window.addEventListener("turbolinks:load", () => {
  document.querySelectorAll('.tabs nav').forEach( (navigation) => {
    let links = navigation.querySelectorAll("a")
    links.forEach( (link) => {
      link.addEventListener("click", (event) => {
        links.forEach( (link) => {
          var target = document.getElementById(link.dataset.target)
          target.classList.add("hidden")
          link.classList.remove('active')
        })
        link.classList.add('active')
        document.getElementById(link.dataset.target).classList.remove("hidden")
      })

      if(link.classList.item('active')){
        document.getElementById(link.dataset.target).classList.remove("hidden")
      }
    })
  })

  document.querySelectorAll('input, form').forEach( (element) => {
    element.addEventListener("updating", () => {
      console.log("updating")
      element.classList.remove("updated")
      element.classList.add("updating")
    })

    element.addEventListener("updated", () => {
      element.classList.remove("updating")
      element.classList.add("updated")
    })
  })

  document.querySelectorAll("[data-remove]").forEach( (form) => {
    form.addEventListener("ajax:success", (event) => {
      let target = document.getElementById(form.dataset.remove)
      target.classList.add('removed')
      setTimeout(() => { target.classList.add('hidden') }, 300)
    });
  })

  document.querySelectorAll("[data-live-update]").forEach( (form) => {
    form.querySelectorAll('input').forEach( (input) => {
      input.addEventListener('change', () => {
        input.dispatchEvent(event("updating"))
        form.dispatchEvent(event("updating"))
        fetch(form.action, { method: form.method, body: new FormData(form) })
        input.dispatchEvent(event("updated"))
        form.dispatchEvent(event("updated"))
      })
    })
  })

  const forms = document.querySelectorAll("form[data-remote-target]");
  forms.forEach((element) => {
    element.addEventListener("ajax:success", (event) => {
      const [_data, _status, xhr] = event.detail;
      document.getElementById(element.dataset.remoteTarget).innerHTML = xhr.responseText
    });
  });

  function event(name) {
    return new Event(name, { bubbles: false, cancelable: false })
  }

  function change_input_number(input, difference) {
    let raw_value = input.value
    var value = parseInt(raw_value)
    if(isNaN(value)){
      value = 0
    }

    input.value = value + difference
    input.dispatchEvent(event("change"))
  }

  document.querySelectorAll('[data-behavior="plus"]').forEach(function(element){
    element.addEventListener('click', () => {
      let input = element.parentElement.querySelector('input')
      change_input_number(input, 1)
    })
  })

  document.querySelectorAll('[data-behavior="minus"]').forEach(function(element){
    element.addEventListener('click', () => {
      let input = element.parentElement.querySelector('input')
      change_input_number(input, -1)
    })
  })

  document.querySelectorAll('[data-submit-children]').forEach(function(element){
    element.addEventListener('click', () => {
      element.querySelector('form').submit()
    })
  })

  document.querySelectorAll('[data-toggle]').forEach(function(element){
    element.addEventListener('click', function(event){
      event.preventDefault()
      var target_element = document.getElementById(element.dataset.toggle)

      target_element.classList.toggle("hidden")
    })
  })

  function refresh_summary() {
    let total_summary_selector = '[data-total-summary]'
    var total_summaries = document.querySelectorAll(total_summary_selector)
    let total_selector = '[data-total]'

    let totals = document.querySelectorAll(total_selector)

    var total_summary_value = 0
    for(var total of totals) {
      var sub_total = parseFloat(total.dataset.total)
      if(isNaN(sub_total)){
        sub_total = 0.0
      }
      total_summary_value += sub_total
    }

    total_summaries.forEach((total_summary) => {
      total_summary.innerHTML = total_summary_value.toFixed(2).replace('.', ',')
    })
  }


  let calculator_selector = "[data-calculator]"
  let value_selector = '[data-value]'
  let total_selector = '[data-total]'
  let price_selector = '[data-price]'
  let count_selector = '[data-count]'
  let calculators = document.querySelectorAll(calculator_selector)

  function recalculate_total(total, values){
    var total_value = 0
    for(changed_value of values) {
      var changed_price = changed_value.querySelector(price_selector)
      var changed_count = changed_value.querySelector(count_selector)

      var count_value = parseInt(changed_count.value)
      var price_value = changed_price.dataset.price

      console.log("PRICE: " + price_value)

      var subtotal = count_value * price_value
      total_value += subtotal
    }

    total.innerHTML = total_value.toFixed(2).replace('.', ',')
    total.dataset.total = total_value.toFixed(2)
    refresh_summary()
  }

  for (var calculator of calculators) {
    let values = calculator.querySelectorAll(value_selector)
    let total = calculator.querySelector(total_selector)
    for(var value of values) {
      let count = value.querySelector('[data-count]')
      count.addEventListener('change', function(){
        recalculate_total(total, values)
      })
    }

    recalculate_total(total, values)
  }

  let gallery = document.getElementById('photos-gallery')
  let gallery_image = document.getElementById('gallery-preview')
  let gallery_title = document.getElementById('gallery-title')

  let images = document.querySelectorAll(".photos-preview")
  var prev = null
  for (var image of images) {
    if(prev){
      image.prev_image = prev
      prev.next_image = image
    }

    prev = image
    image.addEventListener("click", (event) => {
      gallery.classList.remove("hidden")
      gallery_image.source = event.target
      gallery_image.src = gallery_image.source.src
      gallery_title.innerText = gallery_image.source.title
    })
  }

  let next_button = document.querySelector("[data-gallery='next']")
  let prev_button = document.querySelector("[data-gallery='prev']")

  if(next_button){
    next_button.addEventListener("click", () => {
      var source = gallery_image.source

      if(source.next_image){
        gallery_image.source = source.next_image
        gallery_image.src = gallery_image.source.src
        gallery_title.innerText = gallery_image.source.title
      }
    })
  }

  if(prev_button){
    prev_button.addEventListener("click", () => {
      var source = gallery_image.source
      if(source.prev_image){
        gallery_image.source = source.prev_image
        gallery_image.src = gallery_image.source.src
        gallery_title.innerText = gallery_title.source.title
      }
    })
  }
});
